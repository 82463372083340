body {
  margin: 0;
  padding: 0;
  background-color: #282c34; /* Match the background to your theme */
  min-height: 100vh; /* Ensure the background covers the full viewport height */
  overflow: hidden; /* Prevent unnecessary scrolling */
}
/* Ensure the background stays consistent */
.lobby-app {
    margin: 0;
    padding: 0;
    background-color: #282c34; /* Matches App.css background */
    min-height: 100vh; /* Full viewport height */
    display: flex;
    flex-direction: column; /* Allows stacking of multiple containers */
    color: white;
  }
  
  /* Hidden by default, will fade in when loading is complete */
  .content {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease-in, visibility 1s ease-in; /* Smooth fade-in */
  }
  
  /* Fade-in effect for the content */
  .content.fade-in {
    opacity: 1;
    visibility: visible;
  }
  
  .lobbyinfo-container {
    position: absolute; /* Keep positioned relative to the screen */
    top: 2vmin; /* Small margin from the top */
    bottom: 2vmin; /* Small margin from the bottom */
    left: 2vmin; /* Align to the left with a small margin */
    background-color: #3b3f45; /* Background color */
    border-radius: 1vmin; /* Rounded corners */
    padding: 2vmin;
    max-width: 400px; /* Limit the maximum width */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
    overflow: hidden; /* Ensure no content exceeds the container */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out content */
    text-align: center;
  }
/* Lobby header within the unified container */
.lobby-header {
    text-align: center; /* Center-align the header text */
}
  
.lobby-code {
    font-size: 3vmin;
    font-weight: bold;
    color: #61dafb; /* Accent color */
    margin: 0;
}
  
.lobby-status {
    font-size: 2vmin;
    color: #a9a9a9; /* Subtle gray for status */
    margin: 0;
}
  
/* Players and spectators sections */
.player-section, .spectator-section {
    width: 100%; /* Full width of the container */
}
  
.player-section h2, .spectator-section h2 {
    text-align: center; /* Center-align section headings */
    font-size: 2.5vmin;
    color: #61dafb; /* Accent color */
    margin-bottom: 1vmin;
}
  
/* Shared list styles for players and spectators */
.player-list, .spectator-list {
    list-style: none; /* Remove default bullet points */
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column; /* Arrange items in a column */
    gap: 1vmin; /* Space between badges */
    align-items: center; /* Center-align list items */
}
  
/* Card-like badges for players and spectators */
.player-list-item, .spectator-list-item {
    background-color: #3b3f45; /* Match the container background */
    color: white;
    border-radius: 0.5vmin; /* Rounded corners */
    padding: 1vmin;
    text-align: center;
    font-size: 2vmin;
    font-weight: bold;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow */
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Hover effect */
}
  
.player-list-item:hover, .spectator-list-item:hover {
    transform: translateY(-2px); /* Lift effect on hover */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5); /* Enhanced shadow on hover */
}
/* Return to homepage button */
.return-home-button {
    background-color: #61dafb; /* Accent color */
    color: #282c34; /* Dark text for contrast */
    border: none;
    border-radius: 0.5vmin;
    padding: 1vmin 2vmin;
    font-size: 2vmin;
    font-weight: bold;
    cursor: pointer;
    margin-top: 2vmin;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .return-home-button:hover {
    transform: translateY(-2px); /* Lift effect on hover */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5); /* Enhanced shadow */
  }
  
  .return-home-button:active {
    transform: translateY(0); /* Reset lift effect on click */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Reset shadow */
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .modal p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #282c34; /* Dark text for contrast */
  }
  
  .modal button {
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal button:hover {
    background: #0056b3;
  }
  /* Common button styling */
/* Common styling for buttons */
.lobby-button {
  background-color: #4caf50; /* Example color */
  color: white;
  border: none;
  border-radius: 0.5vmin;
  padding: 1vmin 2vmin;
  font-size: 2vmin;
  font-weight: bold;
  cursor: pointer;
  margin: 0.5vmin 0; /* Adjusted margin for closer spacing */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* Optional: Adjust hover/active states */
.lobby-button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5);
}

.lobby-button:active {
  transform: translateY(0);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

/* New container for buttons */
.lobby-button-container {
  display: flex; /* Align buttons horizontally or vertically */
  flex-direction: column; /* Stack buttons vertically */
  gap: 0.5vmin; /* Control spacing between buttons */
  align-items: center; /* Center align the buttons */
  margin-top: 2vmin; /* Add spacing above the button group */
}

/* Specific styling for different buttons */
.toggle-role-button {
  margin-bottom: 2vmin; /* Add spacing for the toggle button */
}
.start-game-button {
  background-color: #4caf50; /* Green color for the start button */
  color: white;
  border: none;
  border-radius: 0.5vmin;
  padding: 0.5vmin 1vmin; /* Reduced padding for smaller size */
  font-size: 1.5vmin; /* Reduced font size */
  font-weight: bold;
  cursor: pointer;
  margin-top: 1vmin;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.start-game-button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5);
}

.start-game-button:active {
  transform: translateY(0);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}
/* Container for secret number */
/* Updated secret number container */
.secretnumber-container {
  position: absolute; /* Positioned relative to the content container */
  top: 2vmin; /* Align to the top */
  right: 2vmin; /* Align to the right */
  background-color: #1f1f1f; /* Background color */
  border-radius: 5vmin; /* Rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); /* Shadow effect */
  padding: 2vmin; /* Add spacing */
  z-index: 10; /* Ensure it's above other elements in content */
  text-align: center;
}

/* Label for the secret number */
.secretnumber-label {
  font-size: 3vmin; /* Larger font size for better visibility */
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 1vmin;
}

/* Display for the secret number */
.secretnumber {
  font-size: 5vmin; /* Increase size for prominence */
  font-weight: bold;
  color: #4caf50; /* Green color for emphasis */
  text-align: center;
}

/* Game Chat Div */
.game-chat-container {
  position: absolute;
  bottom: 6vh;
  left: 22vw;
  width: 60vw;
  height: 30vh;
  background-color: #3b3f45;
  border-radius: 1vmin;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.3);
  padding: 1vmin;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1vmin;
}

.chat-messages {
  flex-grow: 1;
  padding: 1vmin;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1vmin;
}

.chat-message {
  max-width: 75%;
  padding: 1vmin;
  border-radius: 1vmin;
  font-size: 1.8vmin;
  line-height: 1.5;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
}

.chat-message-player-name {
  font-weight: bold;
  color: 	#f3d78c; /* Example: DodgerBlue for player names */
}


.chat-message.system {
  align-self: center;
  background-color: #2d2f33; /* Keep the same background */
  color: yellow; /* Change text color to yellow */
  font-style: italic;
  font-weight: bold;
  text-align: center; /* Center-align the message */
}

.chat-message.resultGood {
  align-self: center;
  background-color: #2d2f33; /* Keep the same background */
  color: #00FF00; /* Change text color to yellow */
  font-style: italic;
  font-weight: bold;
  text-align: center; /* Center-align the message */
}


.chat-message.resultBad {
  align-self: center;
  background-color: #2d2f33; /* Keep the same background */
  color:  #FF0000; /* Change text color to yellow */
  font-style: italic;
  font-weight: bold;
  text-align: center; /* Center-align the message */
}

.chat-message.other {
  align-self: flex-start;
  background-color: #2d2f33;
  color: white;
}

.chat-message.event {
  align-self: center;
  background-color: #ffcc00;
  color: #282c34;
  font-weight: bold;
}

/* Game Controls Div */
.game-controls-container {
  position: absolute;
  top: 20vh;
  left: 22vw;
  width: 60vw;
  height: 35vh;
  background-color: #3b3f45;
  border-radius: 1vmin;
  padding: 1vmin;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.game-controls-container h2 {
  font-size: 2.5vmin;
  color: #61dafb;
}

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

/* Wavelength title */
.wavelength-title {
  position: absolute;
  top: 2vmin; /* Centered at the top */
  left: 50%;
  transform: translateX(-50%);
  font-size: 8vmin; /* Large and bold */
  font-weight: bold;
  color: white;
  font-family: 'Orbitron', sans-serif; /* Cool futuristic font */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 
               0 0 20px rgba(255, 255, 255, 0.6), 
               0 0 30px rgba(255, 255, 255, 0.4); /* Glowing effect */
  z-index: 100; /* Ensure it's above other elements */
}
/* Dropdown styling */
.game-controls-container select {
  margin: 1vmin 0;
  padding: 1vmin;
  font-size: 1.8vmin;
  background-color: #3b3f45;
  color: white;
  border: none;
  border-radius: 0.5vmin;
  width: 26%;
}

.questioning-phase {
  background-color: #2c2f33;
  border-radius: 1vmin;
  padding: 2vmin;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column; /* Stack by default */
  align-items: center;
  gap: 1.5vmin;
  margin: 2vmin 0;
  width: 80%;
  max-width: 1000px;
}

.questioning-phase p {
  font-size: 1.8vmin;
  color: #61dafb;
  margin-bottom: 1vmin;
  font-weight: bold;
}

.questioning-phase .input-container {
  display: flex;
  flex-direction: column; /* Stack dropdown and input */
  gap: 1.5vmin; /* Increased space between elements */
  width: 100%; /* Full width for all inputs */
}

.randomize-button {
  width: 35px; /* Small square */
  height: 35px; /* Small square */
  border-radius: 8px; /* Rounded edges */
  background-color: #4caf50; /* Green color for the button */
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px; /* Small gap from the input field */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Optional shadow */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  max-width: 35px;
}

.randomize-button:hover {
  transform: translateY(-2px); /* Hover effect */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5);
}

.randomize-button:active {
  transform: translateY(0); /* Active effect */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.input-with-icon {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between input field and randomize button */
}
.input-container {
  display: flex;
  flex-direction: column; /* Arrange in a column */
  gap: 16px; /* Space between lines */
  align-items: center; /* Align items to the left */
  width: 100%; /* Ensure responsive layout */
}
.input-with-icon input {
  width: 100%;
  padding-right: 4vmin; /* Space for the circular button */
}

.questioning-phase select,
.questioning-phase input {
  padding: 0.8vmin; /* Uniform padding */
  font-size: 1.6vmin; /* Same font size */
  background-color: #3b3f45;
  color: white;
  border: none;
  border-radius: 0.5vmin;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  line-height: 1.5; /* Adjust line-height for consistency */
  flex: 1; /* Allow elements to grow evenly */
  height: 4vmin; /* Explicit height for uniformity */
  box-sizing: border-box; /* Ensures padding is included in height */
  margin: 0;
}

.questioning-phase .input-container {
  display: flex;
  flex-direction: column; /* Default to stacking */
  gap: 1vmin; /* Space between buttons */
  width: 100%; /* Full width for smaller screens */
}

.questioning-phase .button-container {
  display: flex;
  flex-direction: column; /* Default to stacking */
  gap: 1vmin; /* Space between buttons */
  width: 100%; /* Full width for smaller screens */
}

.questioning-phase button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 0.5vmin;
  padding: 1vmin 2vmin;
  font-size: 1.6vmin;
  font-weight: bold;
  cursor: pointer;
  flex: 1; /* Allow buttons to grow evenly */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  width: 26%;
}

.questioning-phase button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5);
}

.questioning-phase button:active {
  transform: translateY(0);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

/* Answering phase styles */
.answering-phase {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vmin;
  margin-top: 2vmin;
  padding: 2vmin;
  background-color: #2c2f33;
}

.answering-phase .question {
  font-size: 2.5vmin;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}

.answering-phase input[type="text"] {
  background-color: #3b3f45;
  color: white;
  border: none;
  border-radius: 0.5vmin;
  width: 80%;
  padding: 1vmin;
  font-size: 1.8vmin;
}

.answering-phase button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 0.5vmin;
  padding: 1vmin 2vmin;
  font-size: 1.8vmin;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.answering-phase button:hover {
  background-color: #45a049;
}

.answering-phase button:disabled {
  background-color: #888;
  cursor: not-allowed;
}


/* Guessing phase container */
.guessing-phase {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #2c2f33;
  padding: 2vmin;
  border-radius: 5vmin;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  margin: 0;
  width: 60%; /* Responsive width */
  max-width: 700px; /* Limit maximum width */
  animation: fade-in 0.5s ease-in;
}

/* Guessing phase title */
.guessing-title {
  font-size: 2.5vmin;
  color: #61dafb;
  margin-bottom: 1.5vmin;
  font-weight: bold;
  text-align: center;
}

.guessing-phase h3 {
  color: #61dafb;
  font-weight: bold;
}

.guessing-phase select {
  width: 80%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  background: #3b3f45;
  color: white;
}

.guess-imposter-phase {
  background-color: #2c2f33; /* Darker background for contrast */
  border-radius: 1vmin; /* Smooth rounded corners */
  padding: 2vmin; /* Add padding for spacing */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5vmin; /* Space between elements */
  margin: 2vmin 0; /* Space around the component */
  width: 60%; /* Responsive width */
  max-width: 600px; /* Limit maximum width */
}

.guess-imposter-title {
  font-size: 2.5vmin; /* Font size for the title */
  color: #61dafb; /* Accent color */
  font-weight: bold;
  text-align: center; /* Center align the title */
  margin-bottom: 1.5vmin;
}

.imposter-dropdown-container {
  width: 100%; /* Full width for the dropdown */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imposter-dropdown {
  width: 80%; /* Adjust width for responsiveness */
  padding: 1vmin; /* Padding for better usability */
  font-size: 1.6vmin; /* Font size for dropdown options */
  background-color: #3b3f45; /* Match container color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 0.5vmin; /* Smooth rounded corners */
}

.imposter-submit-button {
  margin-top: 1.5vmin; /* Space above button */
  padding: 1vmin 2vmin; /* Padding for better usability */
  font-size: 1.8vmin; /* Font size for the button */
  background-color: #4caf50; /* Green button background */
  color: white; /* Button text color */
  border: none; /* Remove default border */
  border-radius: 0.5vmin; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Hover effects */
}

.imposter-submit-button:hover {
  transform: translateY(-2px); /* Lift effect on hover */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5); /* Enhanced shadow on hover */
}

.imposter-submit-button:active {
  transform: translateY(0); /* Reset lift effect on click */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Reset shadow */
}


/* Slider container */
.guess-slider-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vmin; /* Space between slider and display */
}

/* Slider style */
.guess-slider {
  width: 100%;
  height: 1rem;
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(to right, #61dafb, #4caf50); /* Gradient effect */
  border-radius: 0.5rem;
  outline: none;
  cursor: pointer;
}

.guess-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 2px solid #4caf50;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
}

.guess-slider::-webkit-slider-thumb:hover {
  transform: scale(1.2);
  background: #61dafb;
}

.guess-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 2px solid #4caf50;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
}

.guess-slider::-moz-range-thumb:hover {
  transform: scale(1.2);
  background: #61dafb;
}

/* Guess display */
.guess-display {
  font-size: 2vmin;
  font-weight: bold;
  color: #61dafb;
  text-align: center;
}

.guess-display span {
  font-size: 2.5vmin;
  color: #4caf50;
}

/* Submit button */
.guess-submit-button {
  margin-top: 2vmin;
  padding: 1vmin 2vmin;
  font-size: 2vmin;
  font-weight: bold;
  color: white;
  background-color: #4caf50;
  border: none;
  border-radius: 0.5vmin;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.guess-submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5);
  background-color: #45a049;
}

.guess-submit-button:active {
  transform: translateY(0);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}


/* Style for the player's own messages */
.chat-message-self {
  background-color: #32a852; /* Green for self */
  color: white;
  align-self: flex-end;
}

/* Style for other players' messages */
.chat-message-other {
  background-color: #3282a8; /* Blue for others */
  color: white;
  align-self: flex-start;
}

.countdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.countdown-number {
  font-size: 8rem;
  color: white;
  font-weight: bold;
  animation: fade-scale 1s ease-in-out;
}

@keyframes fade-scale {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.9);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}
.lobby-code-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.lobby-code {
  font-size: 3rem;
  font-weight: bold;
  color: #61dafb; /* Accent color */
}

.tooltip {
  position: absolute;
  top: -1.5rem; /* Adjust position above the lobby code */
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.9rem;
  white-space: nowrap;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 0;
  animation: fade-in 0.3s forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.lobby-code-container:hover .lobby-code {
  text-decoration: underline; /* Optional: underline on hover */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal h3 {
  margin-bottom: 15px;
}

.modal input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 90%;
}

.modal button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.modal button:first-of-type {
  background-color: #007bff;
  color: white;
}

.modal button:last-of-type {
  background-color: #ccc;
}

.game-mode-toggle {
  margin-top: 1rem;
  text-align: center;
}

.mode-toggle-button {
  padding: 0.8rem 1.5rem;
  background-color: #61dafb;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  color: #282c34;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mode-toggle-button:hover {
  background-color: #21a1f1;
}

p {
  font-size: 1rem;
  color: gray;
}

.waiting-box {
  background-color: #333; /* Darker background */
  color: #fff; /* White text */
  padding: 10px;
  border-radius: 10px; /* Rounded corners */
  text-align: center;
  margin-top: 20px;
}

.play-again-button {
  background-color: #28a745; /* Green background */
  color: #fff; /* White text */
  padding: 10px 20px;
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.play-again-button:hover {
  background-color: #218838; /* Darker green on hover */
}




/* Media query for screens below 1200 pixels wide */
@media (max-width: 1200px) {
  .lobbyinfo-container {
    top: 10vh;
    max-width: 250px;
  }
  .secretnumber-container {
    top: 10vh;
    padding: 1vmin;
  }
  .secretnumber-label {
    color: #fff;
    font-size: 2vmin;
    font-weight: 500;
    margin-bottom: 1vmin;
  }
  .secretnumber {
    color: #4caf50;
    font-size: 4vmin;
    font-weight: 700;
    text-align: center;
  }
  .game-controls-container {
    left: 33.5vw;
  }
  .chat-messages, .game-chat-container {
    left: 33.5vw;
  }

  .questioning-phase {
    background-color: #2c2f33; /* Dark background for contrast */
    border-radius: 1vmin; /* Smooth rounded corners */
    padding: 2vmin; /* Add padding for spacing */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5vmin; /* Space between elements */
    margin: 2vmin 0; /* Space around the component */
    width: 60%; /* Responsive width */
    max-width: 600px; /* Limit maximum width */
  }
  
  .questioning-phase p {
    font-size: 1.8vmin; /* Readable font size */
    color: #61dafb; /* Accent color */
    margin-bottom: 1vmin; /* Add spacing below text */
    font-weight: bold; /* Emphasize importance */
  }
  
  .questioning-phase select,
  .questioning-phase input {
    margin: 1vmin 0;
    padding: 1vmin;
    font-size: 1.6vmin;
    background-color: #3b3f45; /* Match the background */
    color: white;
    border: none;
    border-radius: 0.5vmin;
    width: 100%; /* Full width for responsiveness */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  }
  
  .questioning-phase button {
    background-color: #4caf50; /* Green for action buttons */
    color: white;
    border: none;
    border-radius: 0.5vmin;
    padding: 1vmin 2vmin;
    font-size: 1.6vmin;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    width: 100%; /* Full width for consistency */
  }
  
  .questioning-phase button:hover {
    transform: translateY(-2px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5); /* Enhanced hover effect */
  }
  
  .questioning-phase button:active {
    transform: translateY(0); /* Reset lift effect on click */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Reset shadow */
  }
  
  
}

/* Media query for screens below 500 pixels wide */
@media (max-width: 500px) {
  .lobbyinfo-container {
    top: 10vh;
    max-width: 75px;
  }
  .lobby-code-container {
      max-width: 90%;
      }
  .lobby-code {
  font-size: 80%;
  }
  .game-mode-toggle p {
    font-size: 0.5rem;
  }
  .mode-toggle-button {
    font-size: 0.5rem;
    padding: .8rem 1rem;
  }
  .secretnumber-container {
    padding: 1vmin;
    top: 1vh;
  }
  .game-controls-container {
    top: 10vh;
    left: 26vw;
    width: 70vw;
  }
  .game-controls-container h2 {
    font-size: 5.5vmin;
  }

  .chat-messages, .game-chat-container {
    left: 26vw;
    width: 70vw;
    height: 45vh;
    bottom: 2vh;
  }

  .questioning-phase {
    background-color: #2c2f33; /* Dark background for contrast */
    border-radius: 1vmin; /* Smooth rounded corners */
    padding: 2vmin; /* Add padding for spacing */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5vmin; /* Space between elements */
    margin: 2vmin 0; /* Space around the component */
    width: 60%; /* Responsive width */
    max-width: 600px; /* Limit maximum width */
  }
  
  .questioning-phase p {
    font-size: 1.8vmin; /* Readable font size */
    color: #61dafb; /* Accent color */
    margin-bottom: 1vmin; /* Add spacing below text */
    font-weight: bold; /* Emphasize importance */
  }
  
  .questioning-phase select,
  .questioning-phase input {
    margin: 1vmin 0;
    padding: 1vmin;
    font-size: 1.6vmin;
    background-color: #3b3f45; /* Match the background */
    color: white;
    border: none;
    border-radius: 0.5vmin;
    width: 100%; /* Full width for responsiveness */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  }
  
  .questioning-phase button {
    background-color: #4caf50; /* Green for action buttons */
    color: white;
    border: none;
    border-radius: 0.5vmin;
    padding: 1vmin 2vmin;
    font-size: 1.6vmin;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    width: 100%; /* Full width for consistency */
  }
  
  .questioning-phase button:hover {
    transform: translateY(-2px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5); /* Enhanced hover effect */
  }
  
  .questioning-phase button:active {
    transform: translateY(0); /* Reset lift effect on click */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Reset shadow */
  }
  
  
}

