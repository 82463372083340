.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 1;
  transition: opacity 1s ease-in-out; /* Smoother fade-out */
}

.loading-screen.fade-out {
  opacity: 0;
}

.loading-ecg-line {
  width: 40vmin;
  height: auto;
  display: block;
  margin: 0;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: trace 4s linear forwards; /* Extend to cover fade-out */
}

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
.loading-text {
  margin-top: 1rem;
  font-size: 5rem;
  color: white;
  text-align: center;
  font-weight: bold;
  font-family: 'Orbitron', sans-serif; /* Cool futuristic font */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 
               0 0 20px rgba(255, 255, 255, 0.6), 
               0 0 30px rgba(255, 255, 255, 0.4); /* Glowing effect */
  z-index: 100; /* Ensure it's above other elements */
}

@keyframes trace {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}