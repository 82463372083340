/* client/src/App.css */
.App {
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #282c34; /* Set the background color here */
  min-height: 100vh; /* Ensure it covers the full height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; /* Ensure the loading screen and content are positioned correctly */
}
/* Title inside the content box */
.content-title {
  font-size: 8vmin; /* Large font for emphasis */
  font-weight: bold; /* Bold text */
  text-align: center; /* Center the text */
  letter-spacing: 1vmin; /* Spread out the letters */
  color: #ffffff; /* White for contrast */
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7); /* Subtle shadow */
  margin: 0;
  padding-top: 1vmin; /* Adjust spacing from the top */
  position: relative;
  top: 0; /* Ensure it's at the top of the content */
  width: 100%; /* Span the full width */
  text-transform: uppercase; /* Make text uppercase for a bold presence */
}
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content {
  opacity: 0;
  transition: opacity 1s ease-in; /* Smooth fade-in */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content.fade-in {
  opacity: 1;
  visibility: visible; /* Ensure visibility */
}
.nickname-container, .lobby-container {
  background-color: #3b3f45;
  padding: 2vmin;
  border-radius: 1vmin;
  margin: 2vmin;
  text-align: center;
  width: 80vw;
  max-width: 600px; /* Increase max-width for better readability */
}

.nickname-container p, .lobby-container p {
  color: white;
  font-size: 3vmin;
}

.nickname-input, .lobby-input {
  padding: 1vmin;
  margin: 1vmin 0;
  border-radius: 0.5vmin;
  border: 1px solid #ccc; /* Add border to input */
  font-size: 2vmin;
  width: 100%;
  max-width: 500px; /* Increase max-width for better readability */
}

.nickname-button, .lobby-button, .popup-button, .popup-close-button {
  padding: 1vmin 2vmin;
  margin: 1vmin;
  border: none;
  border-radius: 0.5vmin;
  background-color: #61dafb;
  color: #282c34;
  font-size: 2vmin;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 500px; /* Increase max-width for better readability */
}

.nickname-button:hover, .lobby-button:hover, .popup-button:hover, .popup-close-button:hover {
  background-color: #21a1f1;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); /* Dark transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: #ffffff;
  padding: 3vmin 4vmin;
  border-radius: 1vmin;
  text-align: center;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  width: 34vw; /* Fixed width with responsive max */
  position: relative; /* Ensure the close button is positioned correctly */
}

.popup p {
  font-size: 3vmin;
  color: #333;
}

.popup-close-button {
  position: absolute;
  top: 1vmin;
  right: 1vmin;
  background: none;
  border: none;
  font-size: 3vmin;
  cursor: pointer;
  color: #333;
  padding: 0; /* Remove padding to make the clickable area smaller */
  line-height: 1; /* Ensure the cross is centered */
  width: auto; /* Ensure the button doesn't expand */
  height: auto; /* Ensure the button doesn't expand */
}

.popup-close-button:hover {
  background-color: transparent; /* Ensure no background color change on hover */
}

/* client/src/App.css */
.nickname-container .message {
  font-size: 1.5vmin;
  margin-top: 1vmin;
}

.nickname-container .message.success {
  color: green;
}

.nickname-container .message.error {
  color: red;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal p {
  margin-bottom: 20px;
  font-size: 16px;
}

.modal button {
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal button:hover {
  background: #0056b3;
}
.how-to-play-container {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
}

.how-to-play-button {
  background-color: #61dafb;
  color: #282c34;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.how-to-play-button:hover {
  background-color: #21a1f1;
}

.how-to-play-box {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  width: 0;
  height: 80vh;
  overflow: hidden;
  border-radius: 8px 0 0 8px;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5);
  transition: width 0.5s ease-in-out;
}

.how-to-play-container:hover .how-to-play-box {
  width: 400px; /* Adjust the expanded width as needed */
  padding: 20px;
}

.how-to-play-text {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.how-to-play-container:hover .how-to-play-text {
  opacity: 1;
}
